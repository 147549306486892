import './assets/scss/_main.scss'
import Vue from 'vue'
import router from './router'
import App from './App.vue'
import InlineSvg from 'vue-inline-svg'
import axios from 'axios'

import VueGoogleCharts from 'vue-google-charts'

Vue.use(VueGoogleCharts)

Vue.config.productionTip = false

Vue.component('inline-svg', InlineSvg)

new Vue({
  router,
  render: h => h(App),
  data () {
    return {
      lat: -41.2985,
      lon: 173.2444,
      city: 'Nelson',
      country: 'New Zealand',
      formattedAddress: 'Nelson, New Zealand',
      measurement: 'metric',
      displayStyle: 'icons',
      timezone: '',
      contentLoaded: false,
      loaded: false,
      monstersLoaded: false,
      rawMonsters: {},
      rawContent: {},
      allWeatherData: {}
    }
  },
  mounted () {
    this.getContent()
    this.getMonsterData()
    this.getAllWeatherDataLatLon(this.lat, this.lon, this.measurement)
    // this.getLocation()
  },
  methods: {
    getMonsterData () {
      return axios.get(`https://api.airtable.com/v0/appYVvpuMWwPlNfXw/MonsterData`, {
        params: { view: 'Grid view' },
        headers: { Authorization: 'Bearer ' + process.env.VUE_APP_AIRTABLE_API_KEY }
      }).then(
        response => {
          this.rawMonsters = response.data
          this.monstersLoaded = true
        }
      )
    },
    getContent () {
      return axios.get(`https://api.airtable.com/v0/appYVvpuMWwPlNfXw/Content`, {
        params: { view: 'Grid view' },
        headers: { Authorization: 'Bearer ' + process.env.VUE_APP_AIRTABLE_API_KEY }
      }).then(
        response => {
          this.rawContent = response.data
          this.contentLoaded = true
        }
      )
    },
    getAllWeatherDataCityCountry (city, country, latitude, longitude) {
      // return axios
      //   .get(
      //     `https://pro.openweathermap.org/data/3.0/forecast/hourly?q=${city},${country}&units=${this.measurement}&APPID=${process.env.VUE_APP_OPENWEATHER_API_KEY}`
      //   )
      //   .catch(function (error) {
      //     if (error.response) {
      //       console.log('error')
      //       this.getAllWeatherDataLatLon(latitude, longitude, this.measurement)
      //     }
      //   })
      //   .then(response => {
      //     this.allWeatherData = response.data
      //     this.loaded = true
      //   })
    },
    getAllWeatherDataLatLon (lat, lon, measurement) {
      return axios
        .get(
          `https://api.openweathermap.org/data/3.0/onecall?lat=${this.lat}&lon=${this.lon}&units=${this.measurement}&exclude=minutely,alerts&appid=${process.env.VUE_APP_OPENWEATHER_API_KEY}`
        )
        .then(response => {
          this.allWeatherData = response.data
          this.loaded = true
        })
    },
    getLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        )
      } else {
        console.log('The Browser Does not Support Geolocation')
      }
    },
    showError (error) {
      if (error.PERMISSION_DENIED) {
        console.log('The User have denied the request for Geolocation.')
      }
    }
  },
  computed: {
  },
  watch: {
    lat () {

    }
  }
}).$mount('#app')
