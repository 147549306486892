<template>
<div class="modal-wrapper" @click.self="$emit('onToggleModal')">
  <div class="modal">
    <div class="modal-header">
      <div class="title heading3" :style="`color: ${monster.colour}`">{{ monster.title }}</div>
      <div @click="$emit('onToggleModal')"><i class="far fa-times-circle"></i></div>
    </div>
    <div class="content">
      <div class="monster">
          <inline-svg class="monster-icon" :title="monster.title" :src="monster.url" :fill="monster.colour"  width="150px" height="150px" />
      </div>
      <div class="description">{{ monster.information }}</div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    content: String,
    monster: Object
  },
  methods: {
    doThing () {
      console.log('do thing')
    }
  }
}
</script>

<style></style>
