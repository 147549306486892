<template>
  <div class="footer inner">
    <div>Website by <a href="https://www.upshift.co.nz">UpShift</a></div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
