<template>
    <div class="api-output">
      <!-- <div>Next 48 hours in {{ $root.city }}, {{ $root.country }}</div> -->
      <div class="heading3">Next 48 hours in {{ $root.formattedAddress }}</div>
      <div class="weather-monsters">
        <template v-for="(monster, index) in filteredMonsters">
          <div :key="index">
            <div
              :id="monster.id"
              class="monster-tile"
              :style="`color: ${monster.colour}`"
            >
              <inline-svg
                @click="updateSelectedMonster(monster), toggleModal()"
                class="monster-icon"
                :title="monster.title"
                :src="monster.url"
                :fill="monster.colour"
                width="150px"
                height="150px"
              />
              <div class="monster-title">{{ monster.title }}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="charts-wrapper">
        <div :class="['chart', this.$root.displayStyle]">
          <table class="data" style="table-layout: fixed; margin: auto;">
            <tbody>
              <tr>
                <td class="row-label"></td>
                <template v-for="(wd, index) in weatherData">
                  <td class="data-info" :key="index">
                    <img
                      class="weather-icon"
                      :title="wd.description"
                      :src="
                        `http://openweathermap.org/img/wn/${wd.icon}@2x.png`
                      "
                    />
                  </td>
                </template>
              </tr>
              <tr>
                <td class="row-label">
                  <i class="far fa-universal-access"></i>
                  <span v-if="!showIcons()">
                    Universal Thermal Climate Index
                  </span>
                </td>
                <template v-for="(wd, index) in weatherData">
                  <td class="data-bar" :key="index">
                    <div>{{ displayTemperature(wd.UTCI).toFixed() }}&#176;</div>
                    <div
                      :style="
                        `height: ${(150 / (highestTemp - lowestTemp)) *
                          (wd.UTCI - lowestTemp) +
                          10}px; width: 50px; background-color: ${
                          wd.colour
                        }; bottom: 0`
                      "
                    ></div>
                  </td>
                </template>
              </tr>
              <tr v-html="displayDayRow"></tr>
              <tr>
                <td></td>
                <template v-for="(wd, index) in weatherData">
                  <td class="data-info data-time" :key="index">
                    {{ wd.time.replace(/\s/g, '') }}
                  </td>
                </template>
              </tr>
              <tr>
                <td class="row-label" title="Rainfall">
                  <i class="fas fa-cloud-rain"></i
                  ><span v-if="!showIcons()">Rainfall</span>
                </td>
                <template v-for="(wd, index) in weatherData">
                  <td
                    class="data-info"
                    :key="index"
                    :title="
                      calculateRainIntensity(wd.rainfall) +
                        ' Rain - ' +
                        wd.rainfall +
                        'mm / hr'
                    "
                  >
                    <div
                      :class="[
                        'rain-icon',
                        calculateRainIntensity(wd.rainfall)
                      ]"
                    >
                      <i class="fas fa-raindrops"></i>
                    </div>
                    <div v-show="showData()">{{ wd.rainfall }}</div>
                  </td>
                </template>
              </tr>
              <tr>
                <td class="row-label" title="Humidity">
                  <i class="fas fa-tint"></i
                  ><span v-if="!showIcons()">Humidity</span>
                </td>
                <template v-for="(wd, index) in weatherData">
                  <td class="data-info" :key="index">
                    <div class="humidity-icon">
                      <vue-easy-pie-chart :percent="wd.humidity" :size="22" :bar-color="`#9999ff`" :track-color="false" :font-size="0" :line-width="(wd.humidity / 20)" :scale-color="false"></vue-easy-pie-chart>
                    </div>
                    <span v-show="showData()">{{ wd.humidity }}%</span>
                  </td>
                </template>
              </tr>
              <tr v-show="showData()">
                <td class="row-label">
                  <i class="far fa-thermometer-half"></i>
                  <span v-show="showData()">Air Temp.</span>
                </td>
                <template v-for="(wd, index) in weatherData">
                  <td class="data-info" :key="index">
                    {{ displayTemperature(wd.temp).toFixed(0) }}&#176;
                  </td>
                </template>
              </tr>
              <tr>
                <td class="row-label">
                  <i class="far fa-windsock"></i>
                  <span v-show="showData()">Wind</span>
                </td>
                <template v-for="(wd, index) in weatherData">
                  <td class="data-info" :key="index">
                    <div class="wind-cardinal" v-show="showData()">
                      {{ displayCompassCardinal(wd.windDirection) }}
                    </div>
                    <div class="wind-compass">
                      <CompassArrow :degrees="wd.windDirection" />
                    </div>
                    <div class="wind-direction"></div>
                    <div class="wind-speed">
                      {{ displayWindSpeed(wd.windSpeed) }}
                    </div>
                  </td>
                </template>
              </tr>
              <tr>
                <td class="row-label">
                  <i class="far fa-sun"></i>
                  <span v-show="showData()">UV Index</span>
                </td>
                <template v-for="(wd, index) in weatherData">
                  <td class="data-info" :key="index">
                    <div v-show="showData()" class="uvi">{{ wd.uvi }}</div>
                    <inline-svg
                      v-if="wd.uvi"
                      class="uvi-icon"
                      :class="showData() ? 'show-value' : ''"
                      :title="wd.uvi"
                      :src="require('../assets/icons/sun.svg')"
                      :fill="getUVColour(wd.uvi)"
                      width="150px"
                      height="150px"
                    />

                    <!-- <img class="uvi-icon" src="../assets/icons/sun.svg" /> -->
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
    <Modal
      v-if="showMonsterModal"
      :monster="selectedMonster"
      @onToggleModal="toggleModal"
    />
  </div>
</template>

<script>
import CompassArrow from '../components/CompassArrow.vue'
import VueEasyPieChart from 'vue-easy-pie-chart'
import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css'
import Modal from './Modal.vue'

export default {
  components: {
    CompassArrow,
    VueEasyPieChart,
    Modal
  },
  props: {
    monsterData: Array
  },
  data () {
    return {
      days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      cardinals: ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'],
      lowestTemp: 0,
      highestTemp: 0,
      showMonsterModal: false,
      selectedMonster: {}
    }
  },
  methods: {
    updateSelectedMonster (monster) {
      this.selectedMonster = monster
    },
    toggleModal () {
      this.showMonsterModal = !this.showMonsterModal
    },
    showIcons () {
      return (this.$root.displayStyle === 'icons')
    },
    showData () {
      return (this.$root.displayStyle === 'data')
    },
    getUVColour (value) {
      if (value < 3) {
        return 'green'
      } else if (value > 2 & value < 6) {
        return 'yellow'
      } else if (value > 5 & value < 8) {
        return 'orange'
      } else if (value > 7 & value < 11) {
        return 'red'
      } else {
        return 'violet'
      }
    },
    getColour (UTCI) {
      var colour = '#999999'
      var monsters = this.monsterData
      if (monsters !== []) {
        monsters.forEach(function (monster, index) {
          if (UTCI >= monster['minTemp'] && UTCI < monster['maxTemp']) {
            colour = monster['colour']
          }
        })
      }
      return colour
    },
    displayTemperature (temp) {
      if (this.$root.measurement === 'imperial') {
        temp = (temp * 1.8) + 32
      }
      return temp
    },
    calculateRainIntensity (rainfall) {
      var intensity = 'no'
      if (rainfall > 0 && rainfall < 2.5) {
        intensity = 'light'
      } else if (rainfall >= 2.5 && rainfall <= 7.5) {
        intensity = 'moderate'
      } else if (rainfall > 7.5 && rainfall < 50) {
        intensity = 'heavy'
      } else if (rainfall > 50) {
        intensity = 'violent'
      }
      return intensity
    },
    calculateWBGT (T, rh) {
      const Tw =
        T * Math.atan(0.151977 * Math.pow(rh + 8.313659, 0.5)) +
        Math.atan(T + rh) -
        Math.atan(rh - 1.676331) +
        0.00391838 * Math.pow(rh, 1.5) * Math.atan(0.023101 * rh) -
        4.686035
      // WBGT = 0.7 * Tw + 0.3 * T
      const WBGT = 0.7 * Tw + 0.3 * T

      return WBGT
    },
    calculateLowHighTemp () {
      this.highestTemp = this.weatherData.reduce((a, b) =>
        Number(a.UTCI) > Number(b.UTCI) ? a : b
      ).UTCI
      this.lowestTemp = this.weatherData.reduce((a, b) =>
        Number(a.UTCI) > Number(b.UTCI) ? b : a
      ).UTCI
    },
    displayDate (time) {
      return time
    },
    formatDate (unixTimestamp) {
      var date = new Date(unixTimestamp * 1000)
      var hours = date.getHours()
      var minutes = '0' + date.getMinutes()
      var seconds = '0' + date.getSeconds()
      var formattedTime =
        hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)
      return formattedTime
    },
    displayWindSpeed (metresPerSecond) {
      if (this.$root.measurement === 'metric') {
        return (metresPerSecond * 3.6).toFixed(0)
      } else {
        return (metresPerSecond * 2.237).toFixed(0)
      }
    },
    convertTimeZone (date, tzString) {
      return new Date(
        (typeof date === 'string'
          ? new Date(date)
          : date
        ).toLocaleString('en-US', { timeZone: tzString })
      )
    },
    displayCompassCardinal (deg) {
      return this.cardinals[Math.floor(((deg + 22.5) % 360) / 45)]
    },
    calculateUTCI (Ta, RH, va) {
      let ehpa = this.es(Ta) * (RH / 100.0)
      // let dTmrt = Tmrt - Ta
      // if Tmrt is Ta then dTmrt is 0
      let dTmrt = 0 // could possibly be ta
      let pa = ehpa / 10.0
      // let pa = 1
      // let pa = (this.saturatedVaporPressure(Ta) / 10) // in kilo
      // console.log('temp is ' + Ta)
      // console.log('humidity is ' + RH)
      // console.log('wind degree is ' + va)
      // console.log('mean radiaint temp is ' + dTmrt)
      // console.log('vapour pressure(k) is ' + pa)
      let utciApprox = Ta + (0.607562052) +
              ((-0.0227712343) * Ta) +
              ((8.06470249 * Math.pow(10, (-4))) * Ta * Ta) +
              ((-1.54271372 * Math.pow(10, (-4))) * Ta * Ta * Ta) +
              ((-3.24651735 * Math.pow(10, (-6))) * Ta * Ta * Ta * Ta) +
              ((7.32602852 * Math.pow(10, (-8))) * Ta * Ta * Ta * Ta * Ta) +
              ((1.35959073 * Math.pow(10, (-9))) * Ta * Ta * Ta * Ta * Ta * Ta) +
              ((-2.25836520) * va) +
              ((0.0880326035) * Ta * va) +
              ((0.00216844454) * Ta * Ta * va) +
              ((-1.53347087 * Math.pow(10, (-5))) * Ta * Ta * Ta * va) +
              ((-5.72983704 * Math.pow(10, (-7))) * Ta * Ta * Ta * Ta * va) +
              ((-2.55090145 * Math.pow(10, (-9))) * Ta * Ta * Ta * Ta * Ta * va) +
              ((-0.751269505) * va * va) +
              ((-0.00408350271) * Ta * va * va) +
              ((-5.21670675 * Math.pow(10, (-5))) * Ta * Ta * va * va) +
              ((1.94544667 * Math.pow(10, (-6))) * Ta * Ta * Ta * va * va) +
              ((1.14099531 * Math.pow(10, (-8))) * Ta * Ta * Ta * Ta * va * va) +
              ((0.158137256) * va * va * va) +
              ((-6.57263143 * Math.pow(10, (-5))) * Ta * va * va * va) +
              ((2.22697524 * Math.pow(10, (-7))) * Ta * Ta * va * va * va) +
              ((-4.16117031 * Math.pow(10, (-8))) * Ta * Ta * Ta * va * va * va) +
              ((-0.0127762753) * va * va * va * va) +
              ((9.66891875 * Math.pow(10, (-6))) * Ta * va * va * va * va) +
              ((2.52785852 * Math.pow(10, (-9))) * Ta * Ta * va * va * va * va) +
              ((4.56306672 * Math.pow(10, (-4))) * va * va * va * va * va) +
              ((-1.74202546 * Math.pow(10, (-7))) * Ta * va * va * va * va * va) +
              ((-5.91491269 * Math.pow(10, (-6))) * va * va * va * va * va * va) +
              ((0.398374029) * dTmrt) +
              ((1.83945314 * Math.pow(10, (-4))) * Ta * dTmrt) +
              ((-1.73754510 * Math.pow(10, (-4))) * Ta * Ta * dTmrt) +
              ((-7.60781159 * Math.pow(10, (-7))) * Ta * Ta * Ta * dTmrt) +
              ((3.77830287 * Math.pow(10, (-8))) * Ta * Ta * Ta * Ta * dTmrt) +
              ((5.43079673 * Math.pow(10, (-10))) * Ta * Ta * Ta * Ta * Ta * dTmrt) +
              ((-0.0200518269) * va * dTmrt) +
              ((8.92859837 * Math.pow(10, (-4))) * Ta * va * dTmrt) +
              ((3.45433048 * Math.pow(10, (-6))) * Ta * Ta * va * dTmrt) +
              ((-3.77925774 * Math.pow(10, (-7))) * Ta * Ta * Ta * va * dTmrt) +
              ((-1.69699377 * Math.pow(10, (-9))) * Ta * Ta * Ta * Ta * va * dTmrt) +
              ((1.69992415 * Math.pow(10, (-4))) * va * va * dTmrt) +
              ((-4.99204314 * Math.pow(10, (-5))) * Ta * va * va * dTmrt) +
              ((2.47417178 * Math.pow(10, (-7))) * Ta * Ta * va * va * dTmrt) +
              ((1.07596466 * Math.pow(10, (-8))) * Ta * Ta * Ta * va * va * dTmrt) +
              ((8.49242932 * Math.pow(10, (-5))) * va * va * va * dTmrt) +
              ((1.35191328 * Math.pow(10, (-6))) * Ta * va * va * va * dTmrt) +
              ((-6.21531254 * Math.pow(10, (-9))) * Ta * Ta * va * va * va * dTmrt) +
              ((-4.99410301 * Math.pow(10, (-6))) * va * va * va * va * dTmrt) +
              ((-1.89489258 * Math.pow(10, (-8))) * Ta * va * va * va * va * dTmrt) +
              ((8.15300114 * Math.pow(10, (-8))) * va * va * va * va * va * dTmrt) +
              ((7.55043090 * Math.pow(10, (-4))) * dTmrt * dTmrt) +
              ((-5.65095215 * Math.pow(10, (-5))) * Ta * dTmrt * dTmrt) +
              ((-4.52166564 * Math.pow(10, (-7))) * Ta * Ta * dTmrt * dTmrt) +
              ((2.46688878 * Math.pow(10, (-8))) * Ta * Ta * Ta * dTmrt * dTmrt) +
              ((2.42674348 * Math.pow(10, (-10))) * Ta * Ta * Ta * Ta * dTmrt * dTmrt) +
              ((1.54547250 * Math.pow(10, (-4))) * va * dTmrt * dTmrt) +
              ((5.24110970 * Math.pow(10, (-6))) * Ta * va * dTmrt * dTmrt) +
              ((-8.75874982 * Math.pow(10, (-8))) * Ta * Ta * va * dTmrt * dTmrt) +
              ((-1.50743064 * Math.pow(10, (-9))) * Ta * Ta * Ta * va * dTmrt * dTmrt) +
              ((-1.56236307 * Math.pow(10, (-5))) * va * va * dTmrt * dTmrt) +
              ((-1.33895614 * Math.pow(10, (-7))) * Ta * va * va * dTmrt * dTmrt) +
              ((2.49709824 * Math.pow(10, (-9))) * Ta * Ta * va * va * dTmrt * dTmrt) +
              ((6.51711721 * Math.pow(10, (-7))) * va * va * va * dTmrt * dTmrt) +
              ((1.94960053 * Math.pow(10, (-9))) * Ta * va * va * va * dTmrt * dTmrt) +
              ((-1.00361113 * Math.pow(10, (-8))) * va * va * va * va * dTmrt * dTmrt) +
              ((-1.21206673 * Math.pow(10, (-5))) * dTmrt * dTmrt * dTmrt) +
              ((-2.18203660 * Math.pow(10, (-7))) * Ta * dTmrt * dTmrt * dTmrt) +
              ((7.51269482 * Math.pow(10, (-9))) * Ta * Ta * dTmrt * dTmrt * dTmrt) +
              ((9.79063848 * Math.pow(10, (-11))) * Ta * Ta * Ta * dTmrt * dTmrt * dTmrt) +
              ((1.25006734 * Math.pow(10, (-6))) * va * dTmrt * dTmrt * dTmrt) +
              ((-1.81584736 * Math.pow(10, (-9))) * Ta * va * dTmrt * dTmrt * dTmrt) +
              ((-3.52197671 * Math.pow(10, (-10))) * Ta * Ta * va * dTmrt * dTmrt * dTmrt) +
              ((-3.36514630 * Math.pow(10, (-8))) * va * va * dTmrt * dTmrt * dTmrt) +
              ((1.35908359 * Math.pow(10, (-10))) * Ta * va * va * dTmrt * dTmrt * dTmrt) +
              ((4.17032620 * Math.pow(10, (-10))) * va * va * va * dTmrt * dTmrt * dTmrt) +
              ((-1.30369025 * Math.pow(10, (-9))) * dTmrt * dTmrt * dTmrt * dTmrt) +
              ((4.13908461 * Math.pow(10, (-10))) * Ta * dTmrt * dTmrt * dTmrt * dTmrt) +
              ((9.22652254 * Math.pow(10, (-12))) * Ta * Ta * dTmrt * dTmrt * dTmrt * dTmrt) +
              ((-5.08220384 * Math.pow(10, (-9))) * va * dTmrt * dTmrt * dTmrt * dTmrt) +
              ((-2.24730961 * Math.pow(10, (-11))) * Ta * va * dTmrt * dTmrt * dTmrt * dTmrt) +
              ((1.17139133 * Math.pow(10, (-10))) * va * va * dTmrt * dTmrt * dTmrt * dTmrt) +
              ((6.62154879 * Math.pow(10, (-10))) * dTmrt * dTmrt * dTmrt * dTmrt * dTmrt) +
              ((4.03863260 * Math.pow(10, (-13))) * Ta * dTmrt * dTmrt * dTmrt * dTmrt * dTmrt) +
              ((1.95087203 * Math.pow(10, (-12))) * va * dTmrt * dTmrt * dTmrt * dTmrt * dTmrt) +
              ((-4.73602469 * Math.pow(10, (-12))) * dTmrt * dTmrt * dTmrt * dTmrt * dTmrt * dTmrt) +
              ((5.12733497) * pa) +
              ((-0.312788561) * Ta * pa) +
              ((-0.0196701861) * Ta * Ta * pa) +
              ((9.99690870 * Math.pow(10, (-4))) * Ta * Ta * Ta * pa) +
              ((9.51738512 * Math.pow(10, (-6))) * Ta * Ta * Ta * Ta * pa) +
              ((-4.66426341 * Math.pow(10, (-7))) * Ta * Ta * Ta * Ta * Ta * pa) +
              ((0.548050612) * va * pa) +
              ((-0.00330552823) * Ta * va * pa) +
              ((-0.00164119440) * Ta * Ta * va * pa) +
              ((-5.16670694 * Math.pow(10, (-6))) * Ta * Ta * Ta * va * pa) +
              ((9.52692432 * Math.pow(10, (-7))) * Ta * Ta * Ta * Ta * va * pa) +
              ((-0.0429223622) * va * va * pa) +
              ((0.00500845667) * Ta * va * va * pa) +
              ((1.00601257 * Math.pow(10, (-6))) * Ta * Ta * va * va * pa) +
              ((-1.81748644 * Math.pow(10, (-6))) * Ta * Ta * Ta * va * va * pa) +
              ((-1.25813502 * Math.pow(10, (-3))) * va * va * va * pa) +
              ((-1.79330391 * Math.pow(10, (-4))) * Ta * va * va * va * pa) +
              ((2.34994441 * Math.pow(10, (-6))) * Ta * Ta * va * va * va * pa) +
              ((1.29735808 * Math.pow(10, (-4))) * va * va * va * va * pa) +
              ((1.29064870 * Math.pow(10, (-6))) * Ta * va * va * va * va * pa) +
              ((-2.28558686 * Math.pow(10, (-6))) * va * va * va * va * va * pa) +
              ((-0.0369476348) * dTmrt * pa) +
              ((0.00162325322) * Ta * dTmrt * pa) +
              ((-3.14279680 * Math.pow(10, (-5))) * Ta * Ta * dTmrt * pa) +
              ((2.59835559 * Math.pow(10, (-6))) * Ta * Ta * Ta * dTmrt * pa) +
              ((-4.77136523 * Math.pow(10, (-8))) * Ta * Ta * Ta * Ta * dTmrt * pa) +
              ((8.64203390 * Math.pow(10, (-3))) * va * dTmrt * pa) +
              ((-6.87405181 * Math.pow(10, (-4))) * Ta * va * dTmrt * pa) +
              ((-9.13863872 * Math.pow(10, (-6))) * Ta * Ta * va * dTmrt * pa) +
              ((5.15916806 * Math.pow(10, (-7))) * Ta * Ta * Ta * va * dTmrt * pa) +
              ((-3.59217476 * Math.pow(10, (-5))) * va * va * dTmrt * pa) +
              ((3.28696511 * Math.pow(10, (-5))) * Ta * va * va * dTmrt * pa) +
              ((-7.10542454 * Math.pow(10, (-7))) * Ta * Ta * va * va * dTmrt * pa) +
              ((-1.24382300 * Math.pow(10, (-5))) * va * va * va * dTmrt * pa) +
              ((-7.38584400 * Math.pow(10, (-9))) * Ta * va * va * va * dTmrt * pa) +
              ((2.20609296 * Math.pow(10, (-7))) * va * va * va * va * dTmrt * pa) +
              ((-7.32469180 * Math.pow(10, (-4))) * dTmrt * dTmrt * pa) +
              ((-1.87381964 * Math.pow(10, (-5))) * Ta * dTmrt * dTmrt * pa) +
              ((4.80925239 * Math.pow(10, (-6))) * Ta * Ta * dTmrt * dTmrt * pa) +
              ((-8.75492040 * Math.pow(10, (-8))) * Ta * Ta * Ta * dTmrt * dTmrt * pa) +
              ((2.77862930 * Math.pow(10, (-5))) * va * dTmrt * dTmrt * pa) +
              ((-5.06004592 * Math.pow(10, (-6))) * Ta * va * dTmrt * dTmrt * pa) +
              ((1.14325367 * Math.pow(10, (-7))) * Ta * Ta * va * dTmrt * dTmrt * pa) +
              ((2.53016723 * Math.pow(10, (-6))) * va * va * dTmrt * dTmrt * pa) +
              ((-1.72857035 * Math.pow(10, (-8))) * Ta * va * va * dTmrt * dTmrt * pa) +
              ((-3.95079398 * Math.pow(10, (-8))) * va * va * va * dTmrt * dTmrt * pa) +
              ((-3.59413173 * Math.pow(10, (-7))) * dTmrt * dTmrt * dTmrt * pa) +
              ((7.04388046 * Math.pow(10, (-7))) * Ta * dTmrt * dTmrt * dTmrt * pa) +
              ((-1.89309167 * Math.pow(10, (-8))) * Ta * Ta * dTmrt * dTmrt * dTmrt * pa) +
              ((-4.79768731 * Math.pow(10, (-7))) * va * dTmrt * dTmrt * dTmrt * pa) +
              ((7.96079978 * Math.pow(10, (-9))) * Ta * va * dTmrt * dTmrt * dTmrt * pa) +
              ((1.62897058 * Math.pow(10, (-9))) * va * va * dTmrt * dTmrt * dTmrt * pa) +
              ((3.94367674 * Math.pow(10, (-8))) * dTmrt * dTmrt * dTmrt * dTmrt * pa) +
              ((-1.18566247 * Math.pow(10, (-9))) * Ta * dTmrt * dTmrt * dTmrt * dTmrt * pa) +
              ((3.34678041 * Math.pow(10, (-10))) * va * dTmrt * dTmrt * dTmrt * dTmrt * pa) +
              ((-1.15606447 * Math.pow(10, (-10))) * dTmrt * dTmrt * dTmrt * dTmrt * dTmrt * pa) +
              ((-2.80626406) * pa * pa) +
              ((0.548712484) * Ta * pa * pa) +
              ((-0.00399428410) * Ta * Ta * pa * pa) +
              ((-9.54009191 * Math.pow(10, (-4))) * Ta * Ta * Ta * pa * pa) +
              ((1.93090978 * Math.pow(10, (-5))) * Ta * Ta * Ta * Ta * pa * pa) +
              ((-0.308806365) * va * pa * pa) +
              ((0.0116952364) * Ta * va * pa * pa) +
              ((4.95271903 * Math.pow(10, (-4))) * Ta * Ta * va * pa * pa) +
              ((-1.90710882 * Math.pow(10, (-5))) * Ta * Ta * Ta * va * pa * pa) +
              ((0.00210787756) * va * va * pa * pa) +
              ((-6.98445738 * Math.pow(10, (-4))) * Ta * va * va * pa * pa) +
              ((2.30109073 * Math.pow(10, (-5))) * Ta * Ta * va * va * pa * pa) +
              ((4.17856590 * Math.pow(10, (-4))) * va * va * va * pa * pa) +
              ((-1.27043871 * Math.pow(10, (-5))) * Ta * va * va * va * pa * pa) +
              ((-3.04620472 * Math.pow(10, (-6))) * va * va * va * va * pa * pa) +
              ((0.0514507424) * dTmrt * pa * pa) +
              ((-0.00432510997) * Ta * dTmrt * pa * pa) +
              ((8.99281156 * Math.pow(10, (-5))) * Ta * Ta * dTmrt * pa * pa) +
              ((-7.14663943 * Math.pow(10, (-7))) * Ta * Ta * Ta * dTmrt * pa * pa) +
              ((-2.66016305 * Math.pow(10, (-4))) * va * dTmrt * pa * pa) +
              ((2.63789586 * Math.pow(10, (-4))) * Ta * va * dTmrt * pa * pa) +
              ((-7.01199003 * Math.pow(10, (-6))) * Ta * Ta * va * dTmrt * pa * pa) +
              ((-1.06823306 * Math.pow(10, (-4))) * va * va * dTmrt * pa * pa) +
              ((3.61341136 * Math.pow(10, (-6))) * Ta * va * va * dTmrt * pa * pa) +
              ((2.29748967 * Math.pow(10, (-7))) * va * va * va * dTmrt * pa * pa) +
              ((3.04788893 * Math.pow(10, (-4))) * dTmrt * dTmrt * pa * pa) +
              ((-6.42070836 * Math.pow(10, (-5))) * Ta * dTmrt * dTmrt * pa * pa) +
              ((1.16257971 * Math.pow(10, (-6))) * Ta * Ta * dTmrt * dTmrt * pa * pa) +
              ((7.68023384 * Math.pow(10, (-6))) * va * dTmrt * dTmrt * pa * pa) +
              ((-5.47446896 * Math.pow(10, (-7))) * Ta * va * dTmrt * dTmrt * pa * pa) +
              ((-3.59937910 * Math.pow(10, (-8))) * va * va * dTmrt * dTmrt * pa * pa) +
              ((-4.36497725 * Math.pow(10, (-6))) * dTmrt * dTmrt * dTmrt * pa * pa) +
              ((1.68737969 * Math.pow(10, (-7))) * Ta * dTmrt * dTmrt * dTmrt * pa * pa) +
              ((2.67489271 * Math.pow(10, (-8))) * va * dTmrt * dTmrt * dTmrt * pa * pa) +
              ((3.23926897 * Math.pow(10, (-9))) * dTmrt * dTmrt * dTmrt * dTmrt * pa * pa) +
              ((-0.0353874123) * pa * pa * pa) +
              ((-0.221201190) * Ta * pa * pa * pa) +
              ((0.0155126038) * Ta * Ta * pa * pa * pa) +
              ((-2.63917279 * Math.pow(10, (-4))) * Ta * Ta * Ta * pa * pa * pa) +
              ((0.0453433455) * va * pa * pa * pa) +
              ((-0.00432943862) * Ta * va * pa * pa * pa) +
              ((1.45389826 * Math.pow(10, (-4))) * Ta * Ta * va * pa * pa * pa) +
              ((2.17508610 * Math.pow(10, (-4))) * va * va * pa * pa * pa) +
              ((-6.66724702 * Math.pow(10, (-5))) * Ta * va * va * pa * pa * pa) +
              ((3.33217140 * Math.pow(10, (-5))) * va * va * va * pa * pa * pa) +
              ((-0.00226921615) * dTmrt * pa * pa * pa) +
              ((3.80261982 * Math.pow(10, (-4))) * Ta * dTmrt * pa * pa * pa) +
              ((-5.45314314 * Math.pow(10, (-9))) * Ta * Ta * dTmrt * pa * pa * pa) +
              ((-7.96355448 * Math.pow(10, (-4))) * va * dTmrt * pa * pa * pa) +
              ((2.53458034 * Math.pow(10, (-5))) * Ta * va * dTmrt * pa * pa * pa) +
              ((-6.31223658 * Math.pow(10, (-6))) * va * va * dTmrt * pa * pa * pa) +
              ((3.02122035 * Math.pow(10, (-4))) * dTmrt * dTmrt * pa * pa * pa) +
              ((-4.77403547 * Math.pow(10, (-6))) * Ta * dTmrt * dTmrt * pa * pa * pa) +
              ((1.73825715 * Math.pow(10, (-6))) * va * dTmrt * dTmrt * pa * pa * pa) +
              ((-4.09087898 * Math.pow(10, (-7))) * dTmrt * dTmrt * dTmrt * pa * pa * pa) +
              ((0.614155345) * pa * pa * pa * pa) +
              ((-0.0616755931) * Ta * pa * pa * pa * pa) +
              ((0.00133374846) * Ta * Ta * pa * pa * pa * pa) +
              ((0.00355375387) * va * pa * pa * pa * pa) +
              ((-5.13027851 * Math.pow(10, (-4))) * Ta * va * pa * pa * pa * pa) +
              ((1.02449757 * Math.pow(10, (-4))) * va * va * pa * pa * pa * pa) +
              ((-0.00148526421) * dTmrt * pa * pa * pa * pa) +
              ((-4.11469183 * Math.pow(10, (-5))) * Ta * dTmrt * pa * pa * pa * pa) +
              ((-6.80434415 * Math.pow(10, (-6))) * va * dTmrt * pa * pa * pa * pa) +
              ((-9.77675906 * Math.pow(10, (-6))) * dTmrt * dTmrt * pa * pa * pa * pa) +
              ((0.0882773108) * pa * pa * pa * pa * pa) +
              ((-0.00301859306) * Ta * pa * pa * pa * pa * pa) +
              ((0.00104452989) * va * pa * pa * pa * pa * pa) +
              ((2.47090539 * Math.pow(10, (-4))) * dTmrt * pa * pa * pa * pa * pa) +
              ((0.00148348065) * pa * pa * pa * pa * pa * pa)
      return utciApprox
    },
    es (ta) {
      // double[] g = new double[] { -2836.5744, -6028.076559, 19.54263612, -0.02737830188, 0.000016261698, ((double)(7.0229056 * Math.pow(10, -10))), ((double)(-1.8680009 * Math.pow(10, -13))) };
      let g = [
        -2836.5744,
        -6028.076559,
        19.54263612,
        -0.02737830188,
        0.000016261698,
        7.0229056 * Math.pow(10, -10),
        -1.8680009 * Math.pow(10, -13)
      ]
      let tk = ta + 273.15
      let es = 2.7150305 * Math.log(tk)
      // for count, i in enumerate(g):
      for (let count = 0; count < g.length; count++) {
        let i = g[count]
        es = es + i * Math.pow(tk, count - 2)
      }
      es = Math.exp(es) * 0.01
      return es
    },
    saturatedVaporPressure (temp) {
      return 6.11 * Math.pow(10, (7.5 * temp) / (237.3 + temp))
    }
  },

  computed: {
    weatherData () {
      let weatherData = []
      this.$root.allWeatherData.hourly.forEach(element => {
        let date = new Date(element.dt * 1000)
        let UTCI = this.calculateUTCI(element.temp, element.humidity, element.wind_speed)
        let rainfall = ''
        if ('rain' in element) {
          rainfall = element.rain.['1h'].toFixed(1)
        }
        let UVI = (element.uvi.toFixed(0) > 0) ? element.uvi.toFixed(0) : ''
        // console.log(UTCI)
        weatherData.push({
          day: this.convertTimeZone(
            date,
            this.$root.allWeatherData.timezone
          ).toLocaleString('en-US', { weekday: 'long' }),
          time: this.convertTimeZone(
            date,
            this.$root.allWeatherData.timezone
          ).toLocaleString('en-US', { hour: 'numeric', hour12: true }),
          UTCI: UTCI,
          description: element.weather[0].description,
          icon: element.weather[0].icon,
          rainfall: rainfall,
          temp: element.temp,
          humidity: element.humidity,
          uvi: UVI,
          windDirection: element.wind_deg,
          windSpeed: element.wind_speed,
          windGust: element.wind_gust,
          colour: this.getColour(UTCI)
        })
      })
      return weatherData.splice(0, 48)
    },
    filteredMonsters () {
      let filteredMonsters = []
      this.monsterData.forEach(monster => {
        if (this.lowestTemp > monster.maxTemp || this.highestTemp <= monster.minTemp) {
        } else {
          filteredMonsters.push(monster)
        }
      })
      return filteredMonsters
    },
    unixTimestamp () {
      let sunriseDate = new Date(
        this.$root.allWeatherData.current.sunrise * 1000
      )
      let sunsetDate = new Date(this.$root.allWeatherData.current.sunset * 1000)

      // let formattedSunrise = this.formatDate(
      //   this.$root.allWeatherData.current.sunrise
      // )
      // let formattedSunset = this.formatDate(
      //   this.$root.allWeatherData.current.sunset
      // )

      let formattedSunrise = this.convertTimeZone(
        sunriseDate,
        this.$root.allWeatherData.timezone
      ).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
      let formattedSunset = this.convertTimeZone(
        sunsetDate,
        this.$root.allWeatherData.timezone
      ).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })

      return {
        sunrise: formattedSunrise,
        sunset: formattedSunset
      }
    },
    displayDayRow () {
      var label = ''
      var icon = '<i class="far fa-clock"></i>'
      if (this.$root.displayStyle === 'data') {
        label = ' Local Time'
      }
      var output = '<td class="row-label" rowspan="2">' + icon + label + '</td>'
      var hourCount = 0
      var day = ''
      this.weatherData.forEach(hour => {
        if (day !== hour.day && hourCount > 0) {
          output = output + '<td class="data-info data-day" colspan="' + (hourCount + 1) + '">' + day + '</td>'
          hourCount = 0
        } else {
          hourCount = hourCount + 1
          day = hour.day
        }
      })
      if (hourCount > 0) {
        output = output + '<td class="data-info data-day" colspan="' + (hourCount) + '">' + day + '</td>'
      }
      return output
    }
  },
  mounted () {
    this.calculateLowHighTemp()
  },
  watch: {
    weatherData () {
      this.calculateLowHighTemp()
    }
  }
}
</script>
