<template>
  <div class="app-container">
    <Header @onToggleSidebar="showSidebar = !showSidebar" />
    <Weather-info v-if="$root.loaded" :monsterData="monsterData" />
    <Sidebar
      :active="showSidebar"
      :monsterData="monsterData"
      :utciContent="utciContent"
      :credits="credits"
    />
    <Footer />
  </div>
</template>

<script>
import Footer from './Footer.vue'
import Header from './Header.vue'
import Sidebar from './Sidebar.vue'
import WeatherInfo from './WeatherInfo.vue'

export default {
  name: 'heatmonster',
  components: {
    Footer,
    Header,
    Sidebar,
    WeatherInfo
  },
  data () {
    return {
      showSidebar: false
    }
  },
  computed: {
    monsterData () {
      let monsterData = []
      if (this.$root.monstersLoaded) {
        this.$root.rawMonsters.records.forEach(monster => {
          monsterData.push({
            id: monster.id,
            title: monster.fields.Name,
            information: monster.fields.Information,
            minTemp: monster.fields.MinTemp,
            maxTemp: monster.fields.MaxTemp,
            colour: monster.fields.Colour,
            url: monster.fields.MonsterIcon[0].url
          })
        })
      }
      return monsterData
    },
    utciContent () {
      if (this.$root.contentLoaded) {
        return this.$root.rawContent.records[0].fields.UTCI
      } else {
        return ''
      }
    },
    credits () {
      if (this.$root.contentLoaded) {
        return this.$root.rawContent.records[0].fields.Credits
      } else {
        return ''
      }
    }
  }
}
</script>
