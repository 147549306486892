<template>
  <div class="header inner">
    <div class="heading1 title">Heat Monster</div>
    <AddressInput msg="Address input" />
    <div v-show="!sidebarActive" class="burger-icon" @click="sidebarActive = !sidebarActive, $emit('onToggleSidebar')">
      <i class="fas fa-bars"></i>
    </div>
    <div v-show="sidebarActive" class="close-icon" @click="sidebarActive = !sidebarActive, $emit('onToggleSidebar')">
      <i class="fas fa-times"></i>
    </div>
  </div>
</template>

<script>
import AddressInput from './AddressInput.vue'
export default {
  components: { AddressInput },
  data () {
    return {
      sidebarActive: false
    }
  }
}
</script>

<style></style>
