<template>
  <div class="address-toggles">
      <vue-google-autocomplete
        class="search-input"
        ref="address"
        id="map"
        types="(cities)"
        classname="form-control"
        placeholder="Enter location"
        v-on:placechanged="getAddressData"
      >
      </vue-google-autocomplete>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import axios from 'axios'
export default {
  data () {
    return {
      address: '',
      activeChart: ''
    }
  },
  name: 'AddressInput',
  props: {
    msg: String
  },
  components: {
    VueGoogleAutocomplete
  },
  beforeMount () {
    if (this.$route.params.location) {
      console.log('found location')
      this.getLocationFromURL(this.$route.params.location)
    }
  },
  mounted () {
    this.chartClick('chart1')
  },
  methods: {
    getLocationFromURL (location) {
      var key = 'AIzaSyDMk8WFQxfigVCPfxXQ4VMGscL7zgqt4VA'
      var config = {
        method: 'get',
        url: `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${location}&inputtype=textquery&type=cities&key=${key}`,
        headers: {}
      }
      axios(config)
        .then(response => {
          console.log(response)
          // console.log(response.data)
          // let lcAirports = []
          // response.data.results.forEach(result => {
          //   lcAirports.push({
          //     name: result.name,
          //     location: result.geometry.location
          //   })
          // })
          // this.airports = lcAirports
          if (response.data.results.length === 1) {
            this.$root.lat = response.data.results[0].geometry.location.lat
            this.$root.lon = response.data.results[0].geometry.location.lng
            this.$root.formattedAddress =
              response.data.results[0].formatted_address
            this.$root.getAllWeatherDataLatLon(
              response.data.results[0].geometry.location.lat,
              response.data.results[0].geometry.location.lon
            )
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    chartClick (id) {
      this.activeChart = id
    },
    getAddressData (addressData, placeResultData, id) {
      console.log(placeResultData)
      let locationURL = placeResultData.formatted_address
        .toLowerCase()
        // .replace(/\s\s+/g, ' ')
        .replace(' - ', ' ')
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
      if (locationURL !== this.$route.params.location) {
        console.log('updating location')
      }
      this.$router.replace({
        name: 'heat-monster',
        params: { location: locationURL }
      })

      this.updateLocationInformation(
        addressData.locality,
        // addressData.administrative_area_level_1,
        addressData.country,
        addressData.latitude,
        addressData.longitude,
        placeResultData.formatted_address
      )
      this.$root.getAllWeatherDataLatLon(
        addressData.latitude,
        addressData.longitude
      )
    },
    updateLocationInformation (city, country, lat, lon, formattedAddress) {
      this.$root.city = city
      this.$root.country = country
      this.$root.lat = lat
      this.$root.lon = lon
      this.$root.formattedAddress = formattedAddress
    }
  }
}
</script>
