<template>
  <div class="sidebar" :class="active ? 'active' : ''">
    <div class="main-content">
      <div class="content">
        <div class="title heading3">Display</div>
        <div class="meassurement-btn">
          <button
            @click="updateMeasurement('metric')"
            class="btn btn-left"
            :class="{ activeChartClass: this.$root.measurement === 'metric' }"
          >
            Celsius
          </button>
          <button
            @click="updateMeasurement('imperial')"
            class="btn btn-right"
            :class="{ activeChartClass: this.$root.measurement === 'imperial' }"
          >
            Fahrenheit
          </button>
        </div>
        <div class="meassurement-btn">
          <button
            @click="updateDisplayStyle('icons')"
            class="btn btn-left"
            :class="{ activeChartClass: this.$root.displayStyle === 'icons' }"
          >
            Icons
          </button>
          <button
            @click="updateDisplayStyle('data')"
            class="btn btn-right"
            :class="{ activeChartClass: this.$root.displayStyle === 'data' }"
          >
            Data
          </button>
        </div>
      </div>
      <div class="content">
        <div class="accordion-heading" @click="e => toggleAccordion(e)">
          <div class="title heading3">What is UTCI</div>
          <div class="plus-icon">
            <i class="fas fa-plus"></i>
          </div>
        </div>
        <div class="accordion-content">
          {{ utciContent }}
        </div>
      </div>
      <div class="content">
        <div class="title heading3">Meet the Monsters</div>
        <div
          class="meet-the-monsters"
          v-for="monster in monsterData"
          :key="monster.id"
        >
          <div
            class="accordion-heading monster"
            @click="e => toggleAccordion(e)"
          >
            <div class="title heading4" :style="`color: ${monster.colour}`">
              {{ monster.title }}
            </div>
            <div class="plus-icon">
              <i class="fas fa-plus"></i>
            </div>
          </div>
          <div class="accordion-content monster">
            <inline-svg
              class="monster-icon"
              :title="monster.title"
              :src="monster.url"
              :fill="monster.colour"
              width="100px"
              height="100px"
            />
            <div class="monster-info">
              {{ monster.information }}
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="accordion-heading" @click="e => toggleAccordion(e)">
          <div class="title heading3">Credits</div>
          <div class="plus-icon">
            <i class="fas fa-plus"></i>
          </div>
        </div>
        <div class="accordion-content">
          {{ credits }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sidebar',
  props: {
    active: Boolean,
    utciContent: String,
    credits: String,
    monsterData: Array
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    toggleAccordion (e) {
      e.target.parentNode.nextElementSibling.classList.toggle('active')
      e.target.parentNode.classList.toggle('active')
    },
    updateMeasurement (measurement) {
      // console.log('in measurement')
      this.$root.measurement = measurement
    },
    updateDisplayStyle (displayStyle) {
      this.$root.displayStyle = displayStyle
    },
    handleScroll () {
      let sidebar = document.getElementsByClassName('sidebar')[0]
      let windowScroll = window.scrollY
      let topPos = sidebar.getBoundingClientRect().top + windowScroll
      let headerHeight = document.getElementsByClassName('header')[0].offsetHeight
      console.log(topPos)
      if (windowScroll < headerHeight) {
        sidebar.classList.remove('sticky')
      } else if (windowScroll >= topPos) {
        sidebar.classList.add('sticky')
      }
    }
  }
}
</script>

<style></style>
